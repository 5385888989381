* {
  margin: 0;
  outline: none;
}

@import url("https://fonts.googleapis.com/css2?family=Catamaran:wght@300;400;500;600;700&display=swap");

* {
  font-family: "Catamaran", sans-serif;
}

body {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: black;
  margin: 0;
  font-family: "Catamaran", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

hr {
  opacity: 0.6;
}

input {
  background-color: transparent;
  border: none;
  color: inherit;
}

input::placeholder {
  color: inherit;
  opacity: 0.3;
}

textarea {
  background-color: transparent;
  border: none;
  color: inherit;
}
